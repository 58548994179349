import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ConverationsListItem from 'Components/common/chat/ChatConversationListItem';
import NoResult from 'Components/layout/NoResult';
import Spinner from 'Components/layout/Spinner';
import Switch from 'Components/forms/Switch';
import Select from 'Components/forms/Select';
import Input from 'Components/forms/Input';

export default class ChatConversationsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        currentConversationId: PropTypes.string,
        onSelectConversation: PropTypes.func.isRequired,
        filters: PropTypes.shape({
            visible: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            elements: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    label: PropTypes.string,
                    type: PropTypes.oneOf([
                        'switch',
                        'select',
                        'text',
                    ]).isRequired,
                    value: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                        PropTypes.bool,
                    ]),
                    options: PropTypes.arrayOf(
                        PropTypes.shape({
                            label: PropTypes.string,
                            value: PropTypes.string,
                        })
                    ),
                })
            ).isRequired,
        }),
        conversationsList: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }),
    };
    static defaultProps = {
        predefinedQuery: {},
        currentConversationId: null,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        enabled: true,
    };

    state = {
        width: 0,
        conversationsExpanded: false,
    };

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    updateWindowDimensions = () => {
        this.setState({ 
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    getFilter = ({ type, id, label, value, options }) => {
        const { filters } = this.props;
        const { onChange } = filters;

        switch (type) {
            case 'switch':
                return (
                    <div className="filter type-switch">
                        <Switch
                            label={label}
                            name={id}
                            onChange={onChange}
                            value={value}
                        />
                    </div>
                );
            case 'select':
                return (
                    <div className="filter type-select">
                        <Select
                            label={label}
                            name={id}
                            onChange={onChange}
                            value={value}
                            options={options}
                        />
                    </div>
                );
            case 'text':
                return (
                    <div className="filter type-text">
                        <Input
                            name={id}
                            label={label}
                            placeholder={label}
                            value={value}
                            onChange={onChange}
                        />
                    </div>
                )
        }
    }

    render() {
        const { conversationsList, currentConversationId, onSelectConversation, filters } = this.props;
        const { width, conversationsExpanded } = this.state;
        
        return (
            <StyledComponent
                className="chat-conversations-list"
                styles={require('./styles')}
            >
                {filters && filters.visible &&
                    <div className="filters-wrapper">
                        <div className="filters-list">
                            {filters.elements
                                .filter(element => element.visible)
                                .map(filter => (
                                    <div className="filter-wrapper" key={filter.id}>
                                        {this.getFilter(filter)}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                <div className="conversations-list-content">
                    {!conversationsList || conversationsList.isLoading && <Spinner />}
                    {conversationsList && conversationsList.elements.length === 0 &&
                        <NoResult />
                    }
                    {conversationsList && width > 768 
                        ? (
                        conversationsList.elements.map(chatConversation => (
                            <ConverationsListItem
                                key={chatConversation.id}
                                conversation={chatConversation}
                                isActive={chatConversation.id === currentConversationId}
                                onSelectConversation={onSelectConversation}
                            />
                    ))) : (
                        <div className="conversation-expand-button">
                            <a
                                className="controls-cta"
                                onClick={() => this.setState(prevState => ({ conversationsExpanded: !prevState.conversationsExpanded }))}
                            >
                                {conversationsExpanded ? 'Zwiń listę konwersacji' : 'Rozwiń listę konwersacji'}
                            </a>
                            {conversationsExpanded && (
                                conversationsList.elements.map(chatConversation => (
                                    <ConverationsListItem
                                        key={chatConversation.id}
                                        conversation={chatConversation}
                                        isActive={chatConversation.id === currentConversationId}
                                        onSelectConversation={onSelectConversation}
                                    />
                                ))
                            )}
                        </div>
                    )}
                </div>
            </StyledComponent>
        );
    }
}

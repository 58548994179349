import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseToQueryString, parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import ChatConversationsList from 'Components/common/chat/List'
import ConversationWindow from 'Components/admin/chat/ChatConversationWindow';

export default class TrainerChat extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listConversations: PropTypes.func.isRequired,
            listConversationMessages: PropTypes.func.isRequired,
            sendConversationMessages: PropTypes.func.isRequired,
        }).isRequired,
        conversationsList: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }),
    };
    static defaultProps = {
        conversationsList: null,
    };
    state = {
        messages: [],
        isMessagesLoading: false,
    };
    loadMessagesInteval = 0;

    defaultQuery = {
        conversationId: null,
        conversationRole: '',
        active: 'true',
        enabled: 'true',
        search: '',
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const queryObject = parseQueryToObject(location.search);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadConversations();

        this.loadMessagesInteval = setInterval(() => {
            this.loadMessages();
        }, 2500);
    }

    componentWillUnmount = () => {
        clearInterval(this.loadMessagesInteval);
    }

    componentDidUpdate = prevProps => {
        const { conversationsList, history, location } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (conversationsList.isLoaded && !queryObject.conversationId) {
            const firstConveration = conversationsList.elements[0] && conversationsList.elements[0] || null;
            if (firstConveration) {
                history.push(parseToQueryString(location.pathname, { ...queryObject, conversationId: firstConveration.id }));
            }
        }

        if (previousQueryObject.conversationId !== queryObject.conversationId) {
            this.setState({ isMessagesLoading: true });
            this.loadMessages()
                .then(() => this.setState({ isMessagesLoading: false }));
        }

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadConversations();
        }
    }

    loadConversations = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.listConversations({
            page: 1,
            perPage: 100,
            conversationRole: queryObject.conversationRole,
            search: queryObject.search,
            enabled: queryObject.enabled === 'true'
                ? true
                : queryObject.enabled === 'false'
                    ? false
                    : undefined
        });
    }

    loadMessages = () => {
        const { actions } = this.props;
        const { perPage } = this.state;
        const queryObject = this.getQueryConfig();

        return actions.listConversationMessages({
            conversationId: queryObject.conversationId,
            perPage,
            ignoreStore: true,
        })
            .then(response => {
                this.setState({ messages: response.chatMessages.elements });
            });
    }

    loadEarlierMessages = () => {
        this.setState(prevState => ({ perPage: prevState.perPage + 100 }), () => {
            this.loadMessages();
        });
    }

    onSend = (messages = []) => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        return actions.sendConversationMessages({ conversationId: queryObject.conversationId, messages });
    }

    onSearchChange = (filter) => {
        const { history, location } = this.props;
        const queryObject = this.getQueryConfig();

        history.push(parseToQueryString(location.pathname, { ...queryObject, [filter.name]: filter.value }));
    }

    render() {
        const { history, location, conversationsList } = this.props;
        const { messages, isMessagesLoading } = this.state;

        const queryObject = this.getQueryConfig();

        const currentConversation = conversationsList && conversationsList.elements
            .find(element => element.id === queryObject.conversationId);
          
        return (
            <StyledComponent className="page-chat" styles={require('./styles')}>
                <div className="chat-container">
                    <div className="conversations-list-container">
                        <ChatConversationsList
                            history={history}
                            location={location}
                            conversationsList={conversationsList}
                            onSelectConversation={nextConversation => history.push(parseToQueryString(location.pathname, { ...queryObject, conversationId: nextConversation.id }))}
                            filters={{
                                visible: true,
                                onChange: filter => this.onSearchChange(filter),
                                elements: [{
                                    visible: true,
                                    id: 'search',
                                    label: 'Szukaj',
                                    type: 'text',
                                    value: queryObject.search,
                                }],
                            }}
                        />
                    </div>
                    <div className="chat-window-container">
                        {currentConversation && 
                            <ConversationWindow
                                conversation={currentConversation}
                                messages={messages || []}
                                isLoading={isMessagesLoading}
                                onSend={this.onSend}
                                onLoadEarlierMessages={this.loadEarlierMessages}
                            />
                        }
                    </div>
                </div>
            </StyledComponent>
        );
    }
}

import { css } from '@emotion/core';

export default (props, state) =>
    css({
        '.conversation': {
            padding: '1.5em',
            borderBottom: '1px solid #EEEEEE',
            background: 'white',
            cursor: 'pointer',
            boxShadow: '0 0 1em 0 rgba(0,0,0,0.1)',

            '.conversation-last-message': {
                color: '#666666',
                marginBottom: '.5em',
                fontSize: '.9em',
            },

            '.conversation-users-list': {
                marginTop: '.3em',
                color: '#333333',
                display: 'flex',
                flexDirection: 'column',

                '.conversation-user': {
                    marginBottom: '.3em',
                    fontSize: '.9em',
                    fontWeight: '500',
                },
            },

            '.conversation-controls': {
                marginTop: '1em',
                paddingTop: '1em',
                borderTop: '1px solid #CCCCCC',
                display: 'none',
                justifyContent: 'space-around',
                alignItems: 'center',

                '.conversation-control': {
                    cursor: 'pointer',
                    color: '#0981f8',
                    fontWeight: '600',

                    '&:hover': {
                        color: '#76bafd',
                    },
                },
            },

            '&.active': {
                boxShadow: '-.3em 0 0 0 #0981f8 inset',
                background: '#EEEEEE',

                '.conversation-controls': {
                    display: 'flex',
                },
            },

            '&.disabled': {
                opacity: .5,
            },
        },
    });


import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css `
        .chat-container {
            display: flex;
            justifyContent: space-between;
            alignItems: flex-start;
            position: relative;
            height: 100vh;
            width: 100%;
            padding: 0;

            .conversations-list-container {
                width: 35%;
                maxWidth: 20em;
                height: 100%;
                top: 0;
                left: 0;
                borderRight: 1px solid #CCCCCC;

                .controls {
                    .control {
                        padding: 2em 0;
                        background: #2d2d2d;
                        color: white;
                        textAlign: center;
                        display: flex;
                        justifyContent: center;
                        alignItems: center;
                        fontWeight: 600;
                        cursor: pointer;

                        svg {
                            marginLeft: 1em;
                        },

                        &:hover {
                            background: #424242;
                        }
                    }
                }
            }
            .chat-window-container {
                width: 100%;
                height: 80%;
                top: 0;
                right: 0;
            }
        }

        @media (max-width: ${variables.tabletS}) {
            .chat-container {
                flex-direction: column;
                overflow: auto;
                
                .conversations-list-container {
                    width: 100%;
                    height: auto;

                    .chat-conversations-list {
                        .conversations-list-content {
                            max-height: 20em;
                        }
                    }
                }
            }
        }
    `
import { css } from '@emotion/core';

export default (props, state) =>
    css({
        '.filters-wrapper': {
            background: 'white',
            padding: '1em',
            borderBottom: '1px solid #CCCCCC',

            '.filters-list': {
                marginTop: '.5em',

                '.filter-wrapper': {
                    '.filter': {
                        '&.type-select': {
                            '.form-select-component': {
                                '.input-label': {
                                    fontSize: '.9em',
                                },
                                select: {
                                    height: '3em',
                                },
                            },
                        },
                    },
                },
            },
        },

        '.conversations-list-content': {
            maxHeight: '81vh',
            overflow: 'auto',
            
            '.conversation-expand-button': {
                textAlign: 'center',
                padding: '1em',
                fontWeight: '600',
                cursor: 'pointer',
    
                '.conversations-list-item': {
                    marginTop: '1em',
                }
            }
        }
    });
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

import { getFormattedDate } from 'Utils/date';

export default class ChatConverationListItem extends Component {
    static propTypes = {
        conversation: PropTypes.object.isRequired,
        isActive: PropTypes.bool,
        onSelectConversation: PropTypes.func.isRequired,
    };
    static defaultProps = {
        isActive: false,
    };
    
    render() {
        const { conversation, isActive, onSelectConversation } = this.props;

        return (
            <StyledComponent className="conversations-list-item" styles={require('./styles')}>
                <div
                    className={classNames({
                        conversation: true,
                        active: isActive,
                        disabled: !conversation.enabled,
                    })}
                    onClick={() => onSelectConversation(conversation)}
                    key={conversation.id}
                >
                    <div className="conversation-last-message">
                        {conversation.lastMessage && conversation.lastMessage.createdAt ? getFormattedDate(conversation.lastMessage.createdAt) : '---'}
                    </div>
                    <div className="conversation-users-list">
                        {conversation.users.map(user => (
                            <span key={conversation.id + user.id} className="conversation-user">
                                {user.name} {user.surname}
                            </span>
                        ))}
                    </div>
                </div>
            </StyledComponent>
        );
    }
}

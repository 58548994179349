import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        width: 100%;
        
        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1em;
            color: #979797;
            font-size: 1.2em;
        }

        .fa-search {
            padding: .3em;
            font-size: 2em;
        }
    `;
